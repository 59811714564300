<script>
  import { get } from 'vuex-pathify'
  import screen from '@/helpers/screen'

  export default {
    props: {
      /** The type of interstitial screen to display. */
      type: {
        type: String,
        required: true,
        validator: (value) => ['welcome', 'customizeStart', 'customizeComingSoon'].includes(value),
      },
    },
    data() {
      return {
        addedTimeout: null,
      }
    },
    computed: {
      ...get('config', [
        'marketplaceEnabled',
        'getFormattedCurrentBoxStartDate',
        'isCurrentBoxWaitlist',
      ]),
      ...get('customize', ['isSelectedSubscriptionQuarterly', 'isSelectedSubscriptionAnnual']),
      ...get('account', ['isAlltruePlusSubscriber']),
      ...get('feature', ['flags']),
      screen,
      copyByType() {
        return {
          welcome: {
            heading: 'Welcome to the <br /> Alltrue Community!',
            subHeading: '',
            imageSrc: '/static/InvitationSummer.png',
          },
          customizeStart: {
            heading: 'Get ready to Customize!',
            subHeading: 'Start customizing your first box.',
            imageSrc: '/static/illustrations/IllustrationBoxCustomize.png',
          },
          customizeComingSoon: {
            heading: 'Customization coming soon!',
            imageSrc: '/static/illustrations/IllustrationBoxCustomize.png',
          },
        }
      },
      isUpgradeToAlltruePlusEnabled() {
        return this.flags['upgrade-to-alltrue-plus'] ?? false
      },
      isSkipToWelcomeEnabled() {
        return this.flags['subscribe-skip-to-welcome'] ?? false
      },
      newMemberMarketIsEnabled() {
        return this.flags['new-member-market'] ?? false
      },
    },
    created() {
      const time = this.type === 'customizeComingSoon' ? 15000 : 2000
      clearTimeout(this.addedTimeout)
      this.addedTimeout = setTimeout(async () => {
        if (this.type === 'welcome') {
          await this.$store.dispatch('account/fetchProfile')

          if (this.isSkipToWelcomeEnabled) {
            this.$navigate({ name: 'SubscribeWelcome' })
          } else if (!this.isCurrentBoxWaitlist && this.isSelectedSubscriptionQuarterly) {
            this.$navigate({
              name: 'CustomizeUpgrade',
              params: { ...this.$route.params },
            })
          } else if (
            !this.isCurrentBoxWaitlist &&
            !this.isAlltruePlusSubscriber &&
            this.isUpgradeToAlltruePlusEnabled &&
            this.isSelectedSubscriptionAnnual
          ) {
            this.$navigate({
              name: 'AlltruePlusUpgrade',
              params: { ...this.$route.params },
            })
          } else {
            this.$navigate({
              name: 'SubscribeInsiders',
              params: { subscribe: 'subscribe', ...this.$route.params },
            })
          }
        } else if (this.type === 'customizeStart') {
          this.$navigate({
            name: 'CustomizeChoice',
            params: {
              choice: 1,
              subscribe: 'subscribe',
              ...this.$route.params,
            },
          })
        } else if (this.type === 'customizeComingSoon') {
          if (this.newMemberMarketIsEnabled) {
            this.$navigate({ name: 'SubscribeWelcome' })
          } else if (this.marketplaceEnabled) {
            this.$navigate({ name: 'Market' })
          } else {
            this.$navigate({ name: 'Home' })
          }
        }
      }, time)
    },
  }
</script>

<template>
  <div
    :class="`flex flex-col items-center max-w-78 ${
      type === 'customizeComingSoon' ? 'md:max-w-110' : ''
    } m-auto`"
  >
    <div class="mt-20 mb-14 text-center">
      <h1 class="font-heading text-4xl leading-none">
        <BaseRichText font-size="4xl" leading="none" v-html="copyByType[type].heading" />
      </h1>
      <p v-if="type === 'customizeComingSoon'" class="mt-2">
        You will receive an email when the customization window opens on
        <span class="font-bold">{{ getFormattedCurrentBoxStartDate }}</span
        >! This season, customization is available to Annual Members after all spoilers are revealed
        — and every product <br v-if="!screen.md" />
        is customizable!
      </p>
      <h2 v-else-if="copyByType[type].subHeading" class="mt-2">
        {{ copyByType[type].subHeading }}
      </h2>
    </div>
    <BaseImage
      :src="copyByType[type].imageSrc"
      alt=""
      background-transparent
      responsive="sm"
      sizes="384px"
    />
    <BaseButton
      v-if="type === 'customizeComingSoon'"
      outline-only
      class="2xs:min-w-82 mt-10"
      :to="{ name: 'SubscribeWelcome' }"
    >
      Got it!
    </BaseButton>
  </div>
</template>
